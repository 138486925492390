import { Injectable } from '@angular/core';
import { Pbkdf2HmacSha256, RSA, RSA_OAEP, Sha256, Sha512, base64_to_bytes, bytes_to_string, string_to_bytes } from 'asmcrypto.js';

// Must import buffer for PSON to work.
import { Buffer } from "buffer"
import { DecryptionResult } from '@app/model/app/encryption/DecryptionResult';
import { result } from 'lodash';

@Injectable({ providedIn: 'root' })

export class RSADecrypt {


    async rsaDecrypt(data: string, passphrase: string): Promise<DecryptionResult> {
        var promise = new Promise<DecryptionResult>((resolve, reject) => {

            try {
                // data = "FDj0ZhEiOAU9TJ7+T2H7THTZ1cUvrNGlkh9QL+eAXZATSAnBqBkSMN5i8iDDgqIs64IKAYl59Ppgu36CWFOMsAhgxCcuBw55CrGK3eUaqZDg/UU6/S0kbfiVaxdcSWqzcyZ2gTaWUlTBpgVDyCEAqzlPNpUqB5Q8FnEFUSBT1ks=";
                // passphrase = "2|k3c40XTtPsxtpqhHXYDuH53saGzCSeoGjAnV4FUKtaYvUyUKkHMMem6AMTlJ17t59xNM5eV6u84sPlMg1N0EgNaf+zWPabUeWL2v3FcnNWoqBERMD4PyNHWjbZRGwWz85Ymt/ybw+nZjLLkh3bE/K+1qbyH3RLdu8aIccLCANtc=|AQAB|M4XqUjSaV+CxRxNwq9jKWj2hRfMKBy0/UFp3YcQU09bWniRJBqNL7tMA8zHi/P9B4/PYYFLHiSrGBpig1f7K/XYeEvkRM/f4Wz5qd52Sq4j+lTa8K5Tt+Fd3YJCRH2P3cNxB7D9G50CfLlElkoas82EDC3EYkzJUpHLpjAH3gvE=|nO5K4W2mQC7cPGLgRuQKdprQuZw412YVa3rNl20htwICA5lAn2eop7rBccBbES/5lnHkKGpKrxHmfpe6dLeXAw==|8I9DtpTFnNJ4yODfnwFwczvc+KkPWCpkyXOv7leIn4Vx04y+seVTPl0fUXGz2/e9iAYnsdeFkk3lpVz3wPzenQ==|PcKKNa2xgBDxG9LN8RhOBd9nxaR1uk+ynln2D2IjoqJnqILnq9Rfy6Lz/pB1Ro5a65pm8IDkY4Hn9GpCNy0JgQ==|k+chtNJHpaHqTWelVffmkZMOy3v2STjXety8IIiFIb9EOtNgM7RRuBg9Ny/3a5koWMegBEIEh+2I8mD0mx1eKQ==|GMCXhSId6JVjrzERy8qVAo4ljX0u5Lbo47K8eGVrU+OMJ/HV+RAy4quOUkYRRDVLElHpEg96J/NgJnlkX65xyw==";
                // let expected = "_u25w7y3kc";

                var privateKey = passphrase.split("|");
                if (false) {
                    // print out the privateKey components
                    for (let i = 0; i < privateKey.length; i++) {
                        console.log("privateKey[" + i + "]: " + privateKey[i]);
                    }
                }
                if (privateKey[0] === '4') {
                    let payload = this.rsaDecryptV4(data, privateKey);
                    resolve(payload);
                } else if (privateKey[0] === '3') {
                    let payload = this.rsaDecryptV3(data, privateKey);
                    resolve(payload);
                } else {
                    let payload = this.rsaDecryptV2(data, privateKey);
                    resolve(payload);
                }
            } catch (error) {
                console.log("error: " + error);
                let decryptionResult: DecryptionResult = new DecryptionResult();
                decryptionResult.success = false;
                decryptionResult.error = error as unknown as string;
                reject(decryptionResult);
            }
        });

        return promise;
    }

    /**
     * Worker to decrypt v4 of data
     * @param data 
     * @param privateKey 
     * @returns 
     */
    private rsaDecryptV4(data: string, privateKey: string[]): DecryptionResult {

        let payload: DecryptionResult = new DecryptionResult();
        try {
            let privateKey4 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha512();
            let rsaDecrypt = new RSA_OAEP(privateKey4, hash);
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: " + error);
            payload.success = false;
            payload.error = error as unknown as string;
        }
        return payload;

    }
    /**
     * Worker to decrypt v3 of data
     * @param data 
     * @param privateKey 
     * @returns 
     */
    private rsaDecryptV3(data: string, privateKey: string[]): DecryptionResult {
        // let payload: DecryptionResult = new DecryptionResult();
        // console.log("RSA Decrypt version 3 not implemented");
        // payload.success = false;
        // payload.error = "RSA Decrypt version 3 not implemented";
        // return payload;
        let payload: DecryptionResult = new DecryptionResult();
        try {
            let privateKey3 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha512();
            let rsaDecrypt = new RSA_OAEP(privateKey3, hash, string_to_bytes("zz"));
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: " + error);
            payload.success = false;
            payload.error = error as unknown as string;
        }
        return payload;

    }

    /**
     * Worker to decrypt v2 of data
     * @param data 
     * @param privateKey 
     * @returns 
     */
    private rsaDecryptV2(data: string, privateKey: string[]): DecryptionResult {

        let payload: DecryptionResult = new DecryptionResult();
        try {

            // For testing
            // data = "FDj0ZhEiOAU9TJ7+T2H7THTZ1cUvrNGlkh9QL+eAXZATSAnBqBkSMN5i8iDDgqIs64IKAYl59Ppgu36CWFOMsAhgxCcuBw55CrGK3eUaqZDg/UU6/S0kbfiVaxdcSWqzcyZ2gTaWUlTBpgVDyCEAqzlPNpUqB5Q8FnEFUSBT1ks=";
            // passphrase = "2|k3c40XTtPsxtpqhHXYDuH53saGzCSeoGjAnV4FUKtaYvUyUKkHMMem6AMTlJ17t59xNM5eV6u84sPlMg1N0EgNaf+zWPabUeWL2v3FcnNWoqBERMD4PyNHWjbZRGwWz85Ymt/ybw+nZjLLkh3bE/K+1qbyH3RLdu8aIccLCANtc=|AQAB|M4XqUjSaV+CxRxNwq9jKWj2hRfMKBy0/UFp3YcQU09bWniRJBqNL7tMA8zHi/P9B4/PYYFLHiSrGBpig1f7K/XYeEvkRM/f4Wz5qd52Sq4j+lTa8K5Tt+Fd3YJCRH2P3cNxB7D9G50CfLlElkoas82EDC3EYkzJUpHLpjAH3gvE=|nO5K4W2mQC7cPGLgRuQKdprQuZw412YVa3rNl20htwICA5lAn2eop7rBccBbES/5lnHkKGpKrxHmfpe6dLeXAw==|8I9DtpTFnNJ4yODfnwFwczvc+KkPWCpkyXOv7leIn4Vx04y+seVTPl0fUXGz2/e9iAYnsdeFkk3lpVz3wPzenQ==|PcKKNa2xgBDxG9LN8RhOBd9nxaR1uk+ynln2D2IjoqJnqILnq9Rfy6Lz/pB1Ro5a65pm8IDkY4Hn9GpCNy0JgQ==|k+chtNJHpaHqTWelVffmkZMOy3v2STjXety8IIiFIb9EOtNgM7RRuBg9Ny/3a5koWMegBEIEh+2I8mD0mx1eKQ==|GMCXhSId6JVjrzERy8qVAo4ljX0u5Lbo47K8eGVrU+OMJ/HV+RAy4quOUkYRRDVLElHpEg96J/NgJnlkX65xyw==";
            // let expected = "_u25w7y3kc";

            let privateKey2 = [
                base64_to_bytes(privateKey[1]),
                base64_to_bytes(privateKey[2]),
                base64_to_bytes(privateKey[3]),
                base64_to_bytes(privateKey[4]),
                base64_to_bytes(privateKey[5]),
                base64_to_bytes(privateKey[6]),
                base64_to_bytes(privateKey[7]),
                base64_to_bytes(privateKey[8])
            ];

            let hash = new Sha256();
            let rsaDecrypt = new RSA_OAEP(privateKey2, hash, string_to_bytes("zz"));
            payload.data = bytes_to_string(rsaDecrypt.decrypt(base64_to_bytes(data)));
            payload.success = true;
        } catch (error) {
            console.log("error: " + error);
            payload.success = false;
            payload.error = error as unknown as string;
        }

        return payload;
    }


}