import { booleanAttribute, Injectable, OnDestroy } from '@angular/core';
import { BaseAPIService } from '../BaseAPIService';
import { environment } from '@environments/environment';
import { GetOrganizationListResponse } from '@app/model/api/organization/GetOrganizationListResponse';
import { Observable, Subscription } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { OrganizationActions } from '@app/store/actions/OrganizationActions';
import { GetOrganizationLicensesResponse } from '@app/model/api/organization/GetOrganizationLicensesResponse';
import { GetOrgMemberLoginHistoryResponse } from '@app/model/api/organization/GetOrgAdminMemberLoginHistoryResponse';
import { GetOrgMemberTeamMembershipResponse } from '@app/model/api/organization/GetOrgMemberTeamMembershipResponse';
import { GetOrgMemberPasswordUtilizationHistoryResponse } from '@app/model/api/organization/GetOrgAdminMemberPasswordUtilizationHistoryResponse';
import { selectOrganizationListResponse } from '@app/store/selectors/OrganizationSelectors';
import { GetApiKeyListingResponse } from '@app/model/api/organization/GetApiKeyListingResponse';
import { GenerateNewApiKeyResponse } from '@app/model/api/organization/GenerateNewApiKeyResponse';
import { GenerateNewApiKeyRequest } from '@app/model/api/organization/GenerateNewApiKeyRequest';
import { DeleteApiKeyResponse } from '@app/model/api/organization/DeleteApiKeyResponse';
import { UpdateOrganizationDetailResponse } from '@app/model/api/organization/UpdateOrganizationDetailResponse';
import { UpdateOrganizationDetailRequest } from '@app/model/api/organization/UpdateOrganizationDetailRequest';
import { GetOrganizationDetailsResponse } from '@app/model/api/organization/GetOrganizationDetailsResponse';
import { GetOrganizationLicensesRequest } from '@app/model/api/organization/GetOrganizationLicensesRequest';
import { InviteOrganizationTeamMembersResponse } from '@app/model/api/organization/InviteOrganizationTeamMembersResponse';
import { InviteOrganizationTeamMembersRequest } from '@app/model/api/organization/InviteOrganizationTeamMembersRequest';
import { InviteOrganizationTeamMemberEntry } from '@app/model/api/organization/InviteOrganizationTeamMemberEntry';
import { CancelTeamInvitationResponse } from '@app/model/api/organization/CancelTeamInvitationResponse';
import { CancelTeamInvitationRequest } from '@app/model/api/organization/CancelTeamInvitationRequest';
import { ResendTeamInvitationResponse } from '@app/model/api/organization/ResendTeamInvitationResponse';
import { ResendTeamInvitationRequest } from '@app/model/api/organization/ResendTeamInvitationRequest';
import { RemoveOrganizationLicenseResponse } from '@app/model/api/organization/RemoveOrganizationLicenseResponse';
import { RemoveOrganizationLicenseRequest } from '@app/model/api/organization/RemoveOrganizationLicenseRequest';
import { RemoveVerifiedDomainAccountResponse } from '@app/model/api/organization/RemoveVerifiedAccountResponse';
import { RemoveVerifiedDomainAccountRequest } from '@app/model/api/organization/RemoveVerifiedDomainAccountRequest';
import { GetOrganizationSettingsResponse } from '@app/model/api/organization/GetOrganizationSettingsResponse';
import { BaseResponse } from '@app/model/api/BaseResponse';
import { UpdateOrganizationSettingRequest } from '@app/model/api/organization/UpdateOrganizationSettingRequest';
import { OrganizationCommandRequest } from '@app/model/api/organization/OrganizationCommandRequest';
import { KeyMaterial } from '@app/model/api/account/KeyMaterial';
import { OrganizationPermissionEnum } from '@app/model/app/organization/OrganizationPermissionEnum';
import { UpdateOrganizationMemberRolesRequest } from '@app/model/api/organization/UpdateOrganizationMemberRolesRequest';
import { UpdateOrganizationMemberRolesResponse } from '@app/model/api/organization/UpdateOrganizationMemberRolesResponse';
import { OrganizationDataEntry } from '@app/model/api/organization/OrganizationDataEntry';
import { GetOrgAdminOrgPasswordUtilizationHistoryResponse } from '@app/model/api/organization/GetOrgAdminOrgPasswordUtilizationHistoryResponse';
import { OrganizationPolicyEntry } from '@app/model/api/organization/OrganizationPolicyEntry';
import { AccountActions } from '@app/store/actions/AccountActions';
import { GetOrganizationPublicKeyResponse } from '@app/model/api/organization/GetOrganizationPublicKeyResponse';
import { AccountKeyDataResponse } from '@app/model/api/account/AccountKeyDataResponse';
import { GetMemberRecoveryPackingKeyInfoResponse } from '@app/model/api/organization/GetMemberRecoveryPackingKeyInfoResponse';
import { NumberArray } from '@app/model/app/NumberArray';
import { OrganizationKeysService } from './OrganizationKeysService';

@Injectable({ providedIn: 'root' })
export class OrganizationService implements OnDestroy {

    private consoleLog: boolean = false;
    readonly organizationList$ = this.store.select(selectOrganizationListResponse);
    private organizationListSubscription: Subscription;
    private organizationListData: GetOrganizationListResponse = new GetOrganizationListResponse();
    private workingOrganizationPermissions: Map<string, string>;
    private workingOrganizationPolicies: OrganizationPolicyEntry[] = [];

    constructor(
        private baseAPIService: BaseAPIService,
        private store: Store,
        private organizationKeysService: OrganizationKeysService

    ) {
        this.organizationListSubscription = this.organizationList$.subscribe(x => {
            // Update the organization List Data
            if (x) {
                this.organizationListData = x;
                this.getWorkingOrganizationPermissionsAndPolicies();
                this.processPolicies();
            } else {
                this.organizationListData = new GetOrganizationListResponse();
                this.workingOrganizationPermissions = new Map<string, string>();
                this.workingOrganizationPolicies = [];
            }
        });
    }

    ngOnDestroy(): void {
        if (this.organizationListSubscription) {
            this.organizationListSubscription.unsubscribe();
        }
    }

    /*************** The logged in user's data ************/

    /**
     * Get the rights to the current working organization
     */
    getWorkingOrganizationPermissionsAndPolicies(): void {
        if (this.consoleLog) console.log("getWorkingOrganizationPermissionsAndPolicies");
        let activeOrganization = this.getWorkingOrganization();
        if (activeOrganization) {
            // convert
            let permissions = new Map(Object.entries(activeOrganization.permissions));
            let policies = activeOrganization.policies;
            this.workingOrganizationPermissions = permissions;
            this.workingOrganizationPolicies = policies;
        }
    }

    processPolicies(): void {
        if (this.consoleLog) console.log("processPolicies");

        // Iterate through the policies and determine how to act per policy.
        this.workingOrganizationPolicies.forEach(policy => {
            if (policy.policy === "packingKeyRecovery") {
                if (policy.policySetting === "enabled" || policy.policySetting === "optional") {
                    // See if we need to send the packing key once it becomes available.
                    if (policy.requiredAction === "send") {
                        if (this.consoleLog) console.log("Need to archive packing key for the organization.");
                        this.store.dispatch(AccountActions.setArchivePackingKey({ archivePackingKey: true }));
                    } else {
                        this.store.dispatch(AccountActions.setArchivePackingKey({ archivePackingKey: false }));
                    }
                }
            }
        });
    }

    /**
     * Finds the id of the active organization
     * @returns 
     */
    getWorkingOrganizationId(): string {
        let activeOrganization = this.getWorkingOrganization();
        if (activeOrganization) {
            return activeOrganization.orgId;
        }
        return "";
    }


    /**
     * Finds the working organization owner id
     * @returns 
     */
    getWorkingOrganizationOwnerId(): number {
        let activeOrganization = this.getWorkingOrganization();
        if (activeOrganization) {
            return activeOrganization.ownerId;
        }
        return -1;
    }

    /**
     * Finds the working organization
     * @returns 
     */
    getWorkingOrganization(): OrganizationDataEntry | undefined {
        let activeOrganization = this.organizationListData.companyData.find((organization) => organization.working);
        return activeOrganization;
    }

    /**
     * Checks to see if the user has the requested permission with a minimum level.  If the user has RW access then they have R access.
     * @param requestedPermission 
     * @param requestedLevel 
     * @returns 
     */
    checkUserHasPermission(requestedPermission: string, requestedLevel: string): boolean {
        let rv = false;
        // Check to see the array contains the requested permission
        if (null !== this.workingOrganizationPermissions && this.workingOrganizationPermissions.has(requestedPermission)) {
            let perm = this.workingOrganizationPermissions.get(requestedPermission);
            // If the perm is equal to the requested level then return true
            if (perm === requestedLevel) {
                rv = true;
            } else if (requestedLevel === OrganizationPermissionEnum.PERMISSION_READ && perm === OrganizationPermissionEnum.PERMISSION_READ_WRITE) {
                // If the requested level is read and the permission is rw then return true
                rv = true;
            }
        }
        return rv;
    }


    /**************** API WORK *********************/

    /**
     * Get the organization list
     * @returns 
     */
    getOrganizationList(): Observable<GetOrganizationListResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/list";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrganizationListResponse>(params, url);
    }

    /**
        * Invite team members
        * @returns 
        */
    inviteTeamMembers(entries: InviteOrganizationTeamMemberEntry[]): Observable<InviteOrganizationTeamMembersResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/invite/users";
        let request = new InviteOrganizationTeamMembersRequest();
        request.users = entries;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<InviteOrganizationTeamMembersResponse>(body, url);
    }


    updateTeamMemberRoles(request: UpdateOrganizationMemberRolesRequest, adminIdsAdded: NumberArray): void {
        let url = environment.API_BASE_URL + "v2/secure/organization/member/roles/update";
        let body = JSON.stringify(request);
        this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateOrganizationMemberRolesResponse>(body, url).subscribe(
            result => {
                this.organizationKeysService.retrieveAndDecryptOrgPackingRecoveryKey().then((decryptedKey) => {
                    this.organizationKeysService.ensureUsersHaveAccessToKey(adminIdsAdded.data, decryptedKey);
                });
                this.store.dispatch(OrganizationActions.updateTeamMemberRolesResponse({ response: result }))
            }
        )
    }


    getOrgMemberPackingKeyForRecovery(customerId: number): Observable<GetMemberRecoveryPackingKeyInfoResponse> {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/member/account/" + customerId + "/packingKey/recovery";
        var params = new HttpParams();
        return this.baseAPIService.getRequestNoErrorHandling<GetMemberRecoveryPackingKeyInfoResponse>(params, url);
    }


    getOrganizationPublicKey(keyType: number): Observable<GetOrganizationPublicKeyResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/key/" + keyType + "/public";
        var params = new HttpParams();
        return this.baseAPIService.getRequestNoErrorHandling<GetOrganizationPublicKeyResponse>(params, url);
    }

    /**
     * cancels the selected team invitations
     * @returns 
     */
    cancelSelectedTeamInvitations(customerIds: number[]): Observable<CancelTeamInvitationResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/invite/cancel";
        let request = new CancelTeamInvitationRequest();
        request.cids = customerIds;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<CancelTeamInvitationResponse>(body, url);
    }

    /**
    * resends the selected team invitations
    * @returns 
    */
    resendSelectedTeamInvitations(customerIds: number[]): Observable<ResendTeamInvitationResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/invite/resend";
        let request = new ResendTeamInvitationRequest();
        request.cids = customerIds;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<ResendTeamInvitationResponse>(body, url);
    }

    /**
    * removes licenses for the selected team members
    * @returns 
    */
    removeOrganizationLicenses(customerIds: number[]): Observable<RemoveOrganizationLicenseResponse> {
        let url = environment.API_BASE_URL + "v2/secure/organization/license/remove";
        let request = new RemoveOrganizationLicenseRequest();
        request.customerIds = customerIds;
        request.inApp = true;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<RemoveOrganizationLicenseResponse>(body, url);
    }


    /**
  * removes verified accounts
  * @returns 
  */
    removeVerifiedAccounts(customerIds: number[]): Observable<RemoveVerifiedDomainAccountResponse> {
        let url = environment.API_BASE_URL + "v2/secure/organization/verifiedDomainMember/remove";
        let request = new RemoveVerifiedDomainAccountRequest();
        request.customerIds = customerIds;
        request.inApp = true;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<RemoveVerifiedDomainAccountResponse>(body, url);
    }

    /**
     * Get the organization license list
     * @returns 
     */
    getOrganizationLicenseList(): Observable<GetOrganizationLicensesResponse> {
        let url = environment.API_BASE_URL + "v1/secure/account/billing/licenses/list";
        var params = new HttpParams();
        let request = new GetOrganizationLicensesRequest();
        request.includeNonLicensedUsers = true;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<GetOrganizationLicensesResponse>(body, url);
    }

    /**
     * Find the active organization
     * @param response 
     */
    findActiveOrganization(response: GetOrganizationListResponse): void {
        if (response.status.code !== 0) {
            return;
        }
        if (response.companyData.length === 0) {
            return;
        }
        // iterate through the company data to find the one where the working flag is set to true
        let activeCompany = response.companyData.find((company) => company.working);
        if (activeCompany) {
            // dispatch the action to set the active organization
            this.store.dispatch(OrganizationActions.setActiveOrganization({ activeOrganization: activeCompany }));
        }
    }

    /**
     * Gets the api keys for the organization.
     * @returns 
     */
    getApiKeyListing(): Observable<GetApiKeyListingResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/apiKey/list";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetApiKeyListingResponse>(params, url);
    }

    deleteApiKey(key: string): Observable<DeleteApiKeyResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/apiKey/" + key;
        return this.baseAPIService.deleteRequestNoErrorHandling<DeleteApiKeyResponse>(url);
    }

    generateNewApiKey(packingKeyMaterial: KeyMaterial, description: string): Observable<GenerateNewApiKeyResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/apiKey/create";
        let request = new GenerateNewApiKeyRequest();
        request.description = description;
        request.publicKey = packingKeyMaterial.publicKey;
        request.privateKey = packingKeyMaterial.encryptedPrivateKey;
        request.data = packingKeyMaterial.checkData;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<GenerateNewApiKeyResponse>(body, url);
    }

    /* Update Org Name
    */
    updateOrgName(newName: string): Observable<UpdateOrganizationDetailResponse> {
        let url = environment.API_BASE_URL + "v2/secure/organization/update/name";
        let request = new UpdateOrganizationDetailRequest();
        request.newValue = newName;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateOrganizationDetailResponse>(body, url);
    }

    /* Update Org Billing  Email
   */
    updateOrgBillingEmail(newEmail: string): Observable<UpdateOrganizationDetailResponse> {
        let url = environment.API_BASE_URL + "v2/secure/organization/update/billingEmail";
        let request = new UpdateOrganizationDetailRequest();
        request.newValue = newEmail;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<UpdateOrganizationDetailResponse>(body, url);
    }

    /**
     * Retrieves the login history for the given customerId.
     * @param customerId 
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    adminRetrieveLoginHistory(customerId: number, startDate: string, endDate: string, statusSelection: string): Observable<GetOrgMemberLoginHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/report/member/loginHistory/" + customerId + "/" + statusSelection + "/" + startDate + "/" + endDate;
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrgMemberLoginHistoryResponse>(params, url);
    }

    /**
     * Retrieves the password utilization history for the given customerId.
     * @param customerId 
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    adminRetrievePasswordUtilizationHistory(customerId: number, startDate: string, endDate: string): Observable<GetOrgMemberPasswordUtilizationHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/report/member/passwordUtilization/" + customerId + "/" + startDate + "/" + endDate;
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrgMemberPasswordUtilizationHistoryResponse>(params, url);
    }


    /**
     * Retrieves the password utilization history for the organization.
     * @param customerId 
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    adminRetrievePasswordUtilizationHistoryForOrg(startDate: string, endDate: string): Observable<GetOrgAdminOrgPasswordUtilizationHistoryResponse> {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/report/org/passwordUtilization/" + startDate + "/" + endDate;
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrgAdminOrgPasswordUtilizationHistoryResponse>(params, url);
    }

    /**
     * Retrieves the team membership for the given customerId that intersects with the org teams.  There may be
     * other personal teams not returned by this call.
     * @param customerId 
     * @returns 
     */
    adminRetrieveTeamMembership(customerId: number): Observable<GetOrgMemberTeamMembershipResponse> {
        let url = environment.API_BASE_URL + "v1/secure/orgAdmin/report/member/teamMembership/" + customerId;
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrgMemberTeamMembershipResponse>(params, url);
    }


    getOrganizationInfo(): Observable<GetOrganizationDetailsResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/info";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrganizationDetailsResponse>(params, url);
    }

    getOrganizationSettings(): Observable<GetOrganizationSettingsResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/settings";
        var params = new HttpParams();
        return this.baseAPIService.getRequest<GetOrganizationSettingsResponse>(params, url);
    }

    updateOrganizationSetting(section: string, key: string, value: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/setting";
        let request = new UpdateOrganizationSettingRequest();
        request.section = section;
        request.key = key;
        request.value = value;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }

    sendOrganizationCommand(command: string): Observable<BaseResponse> {
        let url = environment.API_BASE_URL + "v1/secure/organization/command";
        let request = new OrganizationCommandRequest();
        request.command = command;
        let body = JSON.stringify(request);
        return this.baseAPIService.postRequestNoErrorHandlingApplicationJson<BaseResponse>(body, url);
    }


}