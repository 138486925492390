export class DecryptedOrganizationKey {
    publicKey: string;
    privateKey: string;
    encryptionKey: string;
    dataKey: string;
    keyType: number;
    version: number;
    name: string;
    description: string;
    organizationId: string;
}
