import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class APIErrorCodeTranslatorService {

    constructor(
        private store: Store
    ) {
    }

    generateErrorMessage(errorCode: number): string {
        let message = ""
        switch (errorCode) {
            case 1:
                message = "Account unauthorized.";
                break;
            case 7:
                message = "Invalid MFA security code. Please try again.";
                break;
            case 11:
                message = "Logged out by server.";
                break;
            case 16:
                message = "Logins are temporarily disabled.";
                break;
            case 95:
                message = "Please retry request.  Please refresh.";
                break;
            case 100:
                message = "Unspecified error.";
                break;
            case 101:
                message = "Username is in use. Please choose another.";
                break;
            case 105:
                message = "Password cannot be the username.";
                break;
            case 112:
                message = "Invalid characters in username.";
                break;
            case 113:
                message = "Password is too short.";
                break;
            case 161:
                message = "Problem with billing method.  Please check your credit card.";
                break;
            case 166:
                message = "Problem renewing your subscription.  Please check your credit card.";
                break;
            case 226:
                message = "No rights to edit password.";
                break;
            case 300:
                message = "Invalid email address.";
                break;
            case 307:
                message = "Email aready in use.";
                break;
            case 327:
                message = "Invalid username or password. Please check your username which may be different than your email and password.  These are case-sensitive.";
                break;
            case 328:
                message = "This account is tarpitted.  Please wait and try later.  Further attempts will only increase the time you must wait.";
                break;
            case 334:
                message = "Invalid plan specified.";
                break;
            case 341:
                message = "First name may not be empty.";
                break;
            case 342:
                message = "Last name may not be empty.";
                break;
            case 343:
                message = "The account email does not match the SSO email address.";
                break;
            case 348:
                message = "Password not reset. Too many accounts. Please contact customer support for assistance.";
                break;
            case 354:
                message = "Could not validate MFA code.";
                break;
            case 398:
                message = "Could not locate the reset code.  It may have expired.  Please try resetting your password again.";
                break;
            case 399:
                message = "Could not locate the reset code.  It may have expired.  Please try resetting your password again.";
                break;
            case 425:
                message = "2FA required to update.";
                break;
            case 482:
                message = "Email needs verification.";
                break;
            case 1201:
                message = "Yubikey already added to account.";
                break;
            case 1203:
                message = "Invalid Yubikey registration string.";
                break;
            case 1601:
                message = "SSO logins are not enabled for your account.";
                break;
            case 1602:
                message = "SSO logins are not enabled for your subscription type.";
                break;
            case 1800:
                message = "User not added. An invitation has already been sent to this email.";
                break;
            case 1802:
                message = "User not added. Invalid email.";
                break;
            case 1806:
                message = "User not added. Already a member of the organization.";
                break;
            case 1807:
                message = "Problem inviting members.";
                break;
            case 1808:
                message = "Cannot retrieve offer due to inelligible plan.";
                break;
            case 1809:
                message = "Cannot retrieve offer because trial has already been used.";
                break;
            case 1813:
                message = "Operation failure. Not on business tier plan.";
                break;
            case 1815:
                message = "Domain not added. Invalid domain.";
                break;
            case 1816:
                message = "User not added. Email contains an invalid domain.";
                break;
            case 1817:
                message = "User not added. Email domain is not allowed to join external organizations.";
                break;
            case 1820:
                message = "API Key limit reached.";
                break;
            case 1821:
                message = "API Key required description missing.";
                break;
            case 1822:
                message = "API Key description too long.";
                break;
            case 1823:
                message = "API Key creation failure.";
                break;
            case 2200:
                message = "Invalid key type.";
                break;
            case 2203:
                message = "Key already exists.";
                break;
            case 2204:
                message = "Key request parameters invalid.";
                break;
            case 2205:
                message = "Key request parameters invalid.";
                break;
            case 2206:
                message = "Key request parameters invalid.";
                break;
            case 2207:
                message = "Key request parameters invalid.";
                break;
            case 2208:
                message = "Key request parameters invalid.";
                break;
            default:
                message = "An error occurred.";
                break;
        }
        return message += " Error code: " + errorCode;
    }


}