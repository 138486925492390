import { Injectable, OnDestroy } from '@angular/core';
import { Score, ZxcvbnResult, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root'
})
export class ZxcvbnService implements OnDestroy {
  private consoleLog: boolean = false;
  worker!: Worker;

  constructor(
    private alertService: AlertService,
  ) {
    if (this.consoleLog) console.log("Constructing");
    const options: any = {
      translations: zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
      }
    }
      ;
    zxcvbnOptions.setOptions(options)

    this.worker = new Worker(new URL('../../workers/util/zxcvbn-worker.worker.ts', import.meta.url));
    // this.worker.addEventListener('message', ({ data }) => {
    //   // Do what you want with your generated report data
    //   console.log("worker returned: ", data);
    // })
    // this.worker.onerror = (error) => {
    //   console.error("Error in worker: ", error);
    // }
    // this.worker.onmessage = (message) => {
    //   console.log("Message from worker: ", message);
    // }
    // this.worker.onmessageerror = (error) => {
    //   console.error("Error in worker message: ", error);
    // }

  }

  ngOnDestroy(): void {
    this.worker.terminate();
  }

  async testString(inputString: string): Promise<Score> {
    let result = await this.sendToWorker(inputString);
    return result.score;

    // .then((data) => {
    //   console.log("$$$$$$$ Worker returned: ", data);
    //   return 
    // }).catch((error) => {
    //   console.error("Error in worker: ", error);
    // });



    // inputString = inputString || "";
    // if (this.consoleLog) console.log("testing string");
    // return zxcvbn(inputString).score;
  }

  async testStringWithFullResults(inputString: string): Promise<ZxcvbnResult> {
    inputString = inputString || "";
    // this.worker.postMessage(inputString);

    if (this.consoleLog) console.log("testing with full results");
    // return zxcvbn(inputString);
    let result = this.sendToWorker(inputString);
    return result;
  }

  sendToWorker(inputString: string): Promise<ZxcvbnResult> {
    const channel = new MessageChannel();
    this.worker.postMessage(inputString, [channel.port1]);
    return new Promise((res, rej) => {
      // we listen for a message from the remaining port of our MessageChannel
      channel.port2.onmessage = (evt) => res(evt.data);
    });
  }
}
